import ROUTES from '../utils/routes';
import { ClientConfig } from './config';

const config: ClientConfig = {
  disableModules: [
    'college',
    'lta-memberships',
    'reports',
    'staff',
    'tournaments',
    'rankings',
    'rankings-usta',
    'players',
    'suspension',
    'schools',
    'organisations',
    'ita-players',
    'ita-merge-players',
    'areas',
  ],
  rootUrlRedirectsToTry: [ROUTES.MEMBERSHIPS],
  clientName: 'SAAS',
  logoFilename: '',
  logoAltText: 'NGB',
  logoSmallFilename: 'ita-logo-small.png',
  faviconFilename: '',
  siteTitle: 'Control Centre',
  siteDescription: 'NGB Global Admin Dashboard',
  footerCopyright: 'saas copyright',
  ignoreScopes: true,
  hideFacilitySelect: true,
  isSaaS: true,
};

export default config;
