import ROUTES from '../utils/routes';
import { ClientConfig } from './config';

const config: ClientConfig = {
  disableModules: [
    'college',
    'memberships',
    'schools',
    'lta-memberships',
    'reports',
    'suspension',
    'rankings',
    'rankings-usta',
    'players',
    'organisations',
    'ita-players',
    'ita-merge-players',
    'areas',
  ],
  clientName: 'Clubspark',
  logoAltText: 'Control Center',
  logoSmallFilename: 'cs-logo-small.svg',
  footerLogoFilename: 'cs-logo-footer.png',
  faviconFilename: 'cs-favicon.png',
  rootUrlRedirectsToTry: [ROUTES.TOURNAMENTS, ROUTES.PLAYERS, ROUTES.STAFF],
  siteTitle: 'Control Center',
  siteDescription: 'Admin Competitions Management',
  footerCopyright: 'clubspark copyright',
  ignoreScopes: false,
  headTaxConfigurable: true,
  inviteStaffViaEmailAndName: true,
};

export default config;
