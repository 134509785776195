exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[player]-points-player-index-tsx": () => import("./../../../src/pages/player-points/[player]/index.tsx" /* webpackChunkName: "component---src-pages-[player]-points-player-index-tsx" */),
  "component---src-pages-access-denied-tsx": () => import("./../../../src/pages/access-denied.tsx" /* webpackChunkName: "component---src-pages-access-denied-tsx" */),
  "component---src-pages-college-membership-index-tsx": () => import("./../../../src/pages/college/membership/index.tsx" /* webpackChunkName: "component---src-pages-college-membership-index-tsx" */),
  "component---src-pages-college-team-add-team-member-tsx": () => import("./../../../src/pages/college/team/add-team-member.tsx" /* webpackChunkName: "component---src-pages-college-team-add-team-member-tsx" */),
  "component---src-pages-college-team-index-tsx": () => import("./../../../src/pages/college/team/index.tsx" /* webpackChunkName: "component---src-pages-college-team-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ita-merge-players-index-tsx": () => import("./../../../src/pages/ita-merge-players/index.tsx" /* webpackChunkName: "component---src-pages-ita-merge-players-index-tsx" */),
  "component---src-pages-ita-players-[profile]-box-score-index-tsx": () => import("./../../../src/pages/ita-players/[profile]/[boxScore]/index.tsx" /* webpackChunkName: "component---src-pages-ita-players-[profile]-box-score-index-tsx" */),
  "component---src-pages-ita-players-[profile]-index-tsx": () => import("./../../../src/pages/ita-players/[profile]/index.tsx" /* webpackChunkName: "component---src-pages-ita-players-[profile]-index-tsx" */),
  "component---src-pages-leagues-index-tsx": () => import("./../../../src/pages/leagues/index.tsx" /* webpackChunkName: "component---src-pages-leagues-index-tsx" */),
  "component---src-pages-leagues-leagues-coordinators-index-tsx": () => import("./../../../src/pages/leagues/leagues-coordinators/index.tsx" /* webpackChunkName: "component---src-pages-leagues-leagues-coordinators-index-tsx" */),
  "component---src-pages-leagues-level-config-edit-tsx": () => import("./../../../src/pages/leagues/level-config/edit.tsx" /* webpackChunkName: "component---src-pages-leagues-level-config-edit-tsx" */),
  "component---src-pages-leagues-level-config-index-tsx": () => import("./../../../src/pages/leagues/level-config/index.tsx" /* webpackChunkName: "component---src-pages-leagues-level-config-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-memberships-create-membership-tsx": () => import("./../../../src/pages/memberships/create-membership.tsx" /* webpackChunkName: "component---src-pages-memberships-create-membership-tsx" */),
  "component---src-pages-memberships-index-tsx": () => import("./../../../src/pages/memberships/index.tsx" /* webpackChunkName: "component---src-pages-memberships-index-tsx" */),
  "component---src-pages-memberships-member-member-tsx": () => import("./../../../src/pages/memberships/member/member.tsx" /* webpackChunkName: "component---src-pages-memberships-member-member-tsx" */),
  "component---src-pages-memberships-member-tsx": () => import("./../../../src/pages/memberships/member.tsx" /* webpackChunkName: "component---src-pages-memberships-member-tsx" */),
  "component---src-pages-memberships-membership-tsx": () => import("./../../../src/pages/memberships/membership.tsx" /* webpackChunkName: "component---src-pages-memberships-membership-tsx" */),
  "component---src-pages-memberships-users-tsx": () => import("./../../../src/pages/memberships/users.tsx" /* webpackChunkName: "component---src-pages-memberships-users-tsx" */),
  "component---src-pages-oauth-callback-index-tsx": () => import("./../../../src/pages/oauth-callback/index.tsx" /* webpackChunkName: "component---src-pages-oauth-callback-index-tsx" */),
  "component---src-pages-player-points-index-tsx": () => import("./../../../src/pages/player-points/index.tsx" /* webpackChunkName: "component---src-pages-player-points-index-tsx" */),
  "component---src-pages-players-[profile]-adjust-points-tsx": () => import("./../../../src/pages/players/[profile]/adjust-points.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-adjust-points-tsx" */),
  "component---src-pages-players-[profile]-edit-suspension-tsx": () => import("./../../../src/pages/players/[profile]/edit-suspension.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-edit-suspension-tsx" */),
  "component---src-pages-players-[profile]-suspend-player-tsx": () => import("./../../../src/pages/players/[profile]/suspend-player.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspend-player-tsx" */),
  "component---src-pages-players-[profile]-suspensions-[suspension]-tsx": () => import("./../../../src/pages/players/[profile]/suspensions/[suspension].tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspensions-[suspension]-tsx" */),
  "component---src-pages-players-[profile]-suspensions-add-violation-tsx": () => import("./../../../src/pages/players/[profile]/suspensions/add-violation.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspensions-add-violation-tsx" */),
  "component---src-pages-players-[profile]-suspensions-violations-[violation]-tsx": () => import("./../../../src/pages/players/[profile]/suspensions/violations/[violation].tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspensions-violations-[violation]-tsx" */),
  "component---src-pages-players-[profile]-suspensions-violations-add-tsx": () => import("./../../../src/pages/players/[profile]/suspensions/violations/add.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspensions-violations-add-tsx" */),
  "component---src-pages-players-add-suspension-points-tsx": () => import("./../../../src/pages/players/add-suspension-points.tsx" /* webpackChunkName: "component---src-pages-players-add-suspension-points-tsx" */),
  "component---src-pages-players-index-tsx": () => import("./../../../src/pages/players/index.tsx" /* webpackChunkName: "component---src-pages-players-index-tsx" */),
  "component---src-pages-rankings-group-tsx": () => import("./../../../src/pages/rankings/group.tsx" /* webpackChunkName: "component---src-pages-rankings-group-tsx" */),
  "component---src-pages-rankings-index-tsx": () => import("./../../../src/pages/rankings/index.tsx" /* webpackChunkName: "component---src-pages-rankings-index-tsx" */),
  "component---src-pages-rankings-ranking-tsx": () => import("./../../../src/pages/rankings/ranking.tsx" /* webpackChunkName: "component---src-pages-rankings-ranking-tsx" */),
  "component---src-pages-schools-[school]-edit-tsx": () => import("./../../../src/pages/schools/[school]/edit.tsx" /* webpackChunkName: "component---src-pages-schools-[school]-edit-tsx" */),
  "component---src-pages-schools-add-tsx": () => import("./../../../src/pages/schools/add.tsx" /* webpackChunkName: "component---src-pages-schools-add-tsx" */),
  "component---src-pages-schools-index-tsx": () => import("./../../../src/pages/schools/index.tsx" /* webpackChunkName: "component---src-pages-schools-index-tsx" */),
  "component---src-pages-tournaments-calendar-tsx": () => import("./../../../src/pages/tournaments/calendar.tsx" /* webpackChunkName: "component---src-pages-tournaments-calendar-tsx" */),
  "component---src-pages-tournaments-directors-index-tsx": () => import("./../../../src/pages/tournaments/directors/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-directors-index-tsx" */),
  "component---src-pages-tournaments-directors-invite-tsx": () => import("./../../../src/pages/tournaments/directors/invite.tsx" /* webpackChunkName: "component---src-pages-tournaments-directors-invite-tsx" */),
  "component---src-pages-tournaments-directors-profile-tsx": () => import("./../../../src/pages/tournaments/directors/profile.tsx" /* webpackChunkName: "component---src-pages-tournaments-directors-profile-tsx" */),
  "component---src-pages-tournaments-edit-tournament-tsx": () => import("./../../../src/pages/tournaments/edit-tournament.tsx" /* webpackChunkName: "component---src-pages-tournaments-edit-tournament-tsx" */),
  "component---src-pages-tournaments-events-tsx": () => import("./../../../src/pages/tournaments/events.tsx" /* webpackChunkName: "component---src-pages-tournaments-events-tsx" */),
  "component---src-pages-tournaments-financial-reports-tsx": () => import("./../../../src/pages/tournaments/financial-reports.tsx" /* webpackChunkName: "component---src-pages-tournaments-financial-reports-tsx" */),
  "component---src-pages-tournaments-groups-create-tournament-group-tsx": () => import("./../../../src/pages/tournaments/groups/create-tournament-group.tsx" /* webpackChunkName: "component---src-pages-tournaments-groups-create-tournament-group-tsx" */),
  "component---src-pages-tournaments-groups-group-tsx": () => import("./../../../src/pages/tournaments/groups/group.tsx" /* webpackChunkName: "component---src-pages-tournaments-groups-group-tsx" */),
  "component---src-pages-tournaments-groups-index-tsx": () => import("./../../../src/pages/tournaments/groups/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-groups-index-tsx" */),
  "component---src-pages-tournaments-index-tsx": () => import("./../../../src/pages/tournaments/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-index-tsx" */),
  "component---src-pages-tournaments-level-config-edit-tsx": () => import("./../../../src/pages/tournaments/level-config/edit.tsx" /* webpackChunkName: "component---src-pages-tournaments-level-config-edit-tsx" */),
  "component---src-pages-tournaments-level-config-index-tsx": () => import("./../../../src/pages/tournaments/level-config/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-level-config-index-tsx" */),
  "component---src-pages-tournaments-payouts-report-index-tsx": () => import("./../../../src/pages/tournaments/payouts-report/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-payouts-report-index-tsx" */),
  "component---src-pages-tournaments-payouts-report-payouts-tsx": () => import("./../../../src/pages/tournaments/payouts-report/payouts.tsx" /* webpackChunkName: "component---src-pages-tournaments-payouts-report-payouts-tsx" */),
  "component---src-pages-tournaments-settings-index-tsx": () => import("./../../../src/pages/tournaments/settings/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-settings-index-tsx" */),
  "component---src-pages-tournaments-settings-scorecard-templates-create-template-tsx": () => import("./../../../src/pages/tournaments/settings/scorecard-templates/create-template.tsx" /* webpackChunkName: "component---src-pages-tournaments-settings-scorecard-templates-create-template-tsx" */),
  "component---src-pages-tournaments-settings-scorecard-templates-edit-tsx": () => import("./../../../src/pages/tournaments/settings/scorecard-templates/edit.tsx" /* webpackChunkName: "component---src-pages-tournaments-settings-scorecard-templates-edit-tsx" */),
  "component---src-pages-tournaments-tournaments-report-tsx": () => import("./../../../src/pages/tournaments/tournaments-report.tsx" /* webpackChunkName: "component---src-pages-tournaments-tournaments-report-tsx" */)
}

