import ROUTES from '../utils/routes';
import { ClientConfig } from './config';

const config: ClientConfig = {
  disableModules: [
    'college',
    'memberships',
    'schools',
    'lta-memberships',
    'organisations',
    'ita-players',
    'ita-merge-players',
    'areas',
  ],
  clientName: 'Demo Mordor',
  logoAltText: 'Demo Mordor Control Center',
  logoSmallFilename: 'cs-logo-small.svg',
  footerLogoFilename: 'cs-logo-footer.png',
  faviconFilename: 'cs-favicon.png',
  rootUrlRedirectsToTry: [ROUTES.TOURNAMENTS, ROUTES.PLAYERS, ROUTES.STAFF, ROUTES.REPORTS],
  siteTitle: 'Demo Mordor',
  siteDescription: 'Demo Mordor Control Centre',
  footerCopyright: 'clubspark copyright',
  ignoreScopes: false,
  headTaxConfigurable: true,
  inviteStaffViaEmailAndName: true,
  language: 'en-US',
  fallbackLanguages: ['en-US'],
  disableTournamentSettings: true,
  stripeCountry: 'GB',
};

export default config;
