import { getEnvConfig } from 'src/config/config';
import { GetTournamentForEmailTemplate_tournament as Tournament } from 'src/graphql-types/GetTournamentForEmailTemplate';

import { getToken, isCognito, VenueSystemRole } from './auth';

export const TOURNAMENT_APRROVAL_TEMPLATE_FREE_TEXT = '{{free_text_field}}';

const fetchWithAuth: typeof fetch = (input, init) => {
  if (isCognito()) {
    return fetch(input, { ...(init ?? {}), credentials: 'include' });
  } else {
    const token = getToken();
    return fetch(input, {
      ...(init ?? {}),
      headers: {
        ...(init?.headers ?? {}),
        authorization: `ClubSpark-Auth ${token}`,
      },
    });
  }
};

export const fetchTournamentApprovalEmailTemplate = async (
  t: Tournament,
  accepted: number,
  rejected: number,
  fee?: number | null,
  user?: any,
  orgName?: string,
) => {
  const adminName = user?.FirstName && user?.LastName && `${user.FirstName} ${user.LastName}`;
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_EMAIL_URL}/Emails/VenueContactMessageWithTemplate?venueId=${
      t.primaryLocation.id
    }`,
    {
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        Name: 'TournamentApproval',
        Properties: {
          FreeText: TOURNAMENT_APRROVAL_TEMPLATE_FREE_TEXT,
          TournamentDirectorFirstName: t.director?.firstName,
          NumberRejectedEvents: `${rejected}`,
          NumberApprovedEvents: `${accepted}`,
          UstaSection: orgName,
          Level: t.level?.name,
          TournamentName: t.name,
          TournamentStartDate: t.timings.startDate,
          TournamentEndDate: t.timings.endDate,
          TournamentPrimaryLocation: t.primaryLocation.name,
          TournamentUrl: `${getEnvConfig().HOSTING_URL ?? ''}/tournaments/${t.id}`,
          SectionAdminApproverName: adminName,
          DefaultPhone: user?.PhoneNumber ?? 'N/A',
          DefaultEmail: user?.EmailAddress,
          SanctionFee: `${fee ?? 0}`,
        },
      }),
    },
  );
  if (res.ok) {
    return res.text();
  }
};

export const fetchUserVenues = () => {
  return fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/User/GetCurrentUserVenues/15`);
};

export const fetchCurrentUser = () => {
  return fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/User/GetCurrentUser`);
};

export const forgotPassword = async (email: string) => {
  return await fetch(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v0/account/ForgottenPassword`, {
    method: 'POST',
    body: JSON.stringify({ EmailAddress: email }),
    headers: { 'content-type': 'application/json' },
  });
};

export const getVenueAdmins = async (
  orgId: string,
  limit?: number,
  offset?: number,
  sortingOrder?: string,
  sortingDirection?: number,
) => {
  const info = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v1/Administrators/GetVenueAdministratorsPaged`,
    {
      method: 'POST',
      body: JSON.stringify({
        queryParametersPaged: {
          sorts: [
            {
              sortDirection: sortingDirection ?? 0,
              property: sortingOrder ?? 'FirstName',
            },
          ],
          limit: limit,
          offset: offset,
        },
        venueId: orgId,
        venueRoleSubCategory: '3', // 3 = staff
      }),
      headers: { 'content-type': 'application/json' },
    },
  );

  return info.json();
};

export const getAreaCoordinators = async (
  orgId: string,
  limit?: number,
  offset?: number,
  sortingOrder?: string,
  sortingDirection?: number,
) => {
  const info = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v1/Administrators/GetAllVenueAdministratorsPaged`,
    {
      method: 'POST',
      body: JSON.stringify({
        queryParametersPaged: {
          sorts: [
            {
              sortDirection: sortingDirection ?? 0,
              property: sortingOrder ?? 'FirstName',
            },
          ],
          limit: limit,
          offset: offset,
        },
        venueId: orgId,
        getChildrenLevel: 1,
        venueSystemRoles: 5120,
        childVenueCategories: [30],
      }),
      headers: { 'content-type': 'application/json' },
    },
  );

  return info.json();
};

export const getAllVenues = async () => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetAllVenues?category=Region`);
  if (res.ok) {
    return await res.json();
  }
};

interface AddVenueAdminInput {
  orgId: string;
  externalId?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  scope: number;
}

export const addVenueAdmin = async ({
  orgId,
  externalId,
  scope,
  firstName,
  lastName,
  emailAddress,
}: AddVenueAdminInput) => {
  const contactInfo = externalId
    ? { ExternalID: externalId }
    : { FirstName: firstName, LastName: lastName, EmailAddress: emailAddress };

  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v0/Tournaments/AddVenueContactWithRoles`, {
    method: 'POST',
    body: JSON.stringify({
      InviteContact: true,
      SendInvitationEmail: true,
      InvitationRedirectUrl: getEnvConfig().HOSTING_URL,
      VenueContact: {
        ...contactInfo,
        VenueID: orgId,
        VenueSystemRoles: VenueSystemRole.COMPETITION_ADMINISTRATOR,
        VenueRoles: ['Staff'],
        Scope: scope,
      },
    }),
    headers: { 'content-type': 'application/json' },
  });
  const info = res.ok && (await res.json());
  if (!info) {
    throw 'Error adding venue admin';
  }
  return info;
};

export interface BulkUploadContact {
  FirstName?: string;
  LastName?: string;
  EmailAddress?: string;
  SectionCode: string;
  Role: number;
  VenueRoles: ('Tournament Director' | 'Staff')[];
  ExternalId?: string;
  Scope?: number;
  RedirectUrl?: string;
}

export interface GetOrganisationMembership {
  UAID: string;
  Name: string;
  MembershipStatus: string;
  MembershipExpiryDate: string;
  ContactName: string;
  OrgType: {
    Code: string;
    Name: string;
  };
  OrgStatus: string;
}

export const bulkAddVenueAdmins = async (contacts: BulkUploadContact[]) => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v1/VenueAdminContacts/BulkAddVenueContactWithRoles`,
    {
      method: 'POST',
      body: JSON.stringify({
        VenueContacts: contacts,
        RedirectUrl: getEnvConfig().HOSTING_URL,
      }),
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    },
  );
  if (res.ok) {
    return true;
  }
  throw Error('Error adding contacts');
};

export const searchSectionDistrict = async (term: string): Promise<any[] | undefined> => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetAutoCompleteVenues?term=${encodeURIComponent(
      term,
    )}&adminMode=true&category=Region`,
  );
  if (res.ok) {
    return await res.json();
  }
};

export const getOrganizationMembership = async (venueId: string): Promise<GetOrganisationMembership | undefined> => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetOrganizationDetails/${encodeURIComponent(venueId)}`,
  );
  if (res.ok) {
    return await res.json();
  }
};

export const fetchRegionVenues = async () => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetAllVenues?category=Region`);
  if (res.ok) {
    return await res.json();
  }
};

export const fetchVenues = async () => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetAllVenues?category=Venue`);
  if (res.ok) {
    return await res.json();
  }
};

export const fetchFacilities = async () => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetAllVenues?category=Location&category2=Facility`,
  );
  if (res.ok) {
    return await res.json();
  }
};

export const fetchNationalVenue = async () => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v2/Venue/GetVenueByExternalID?id=n00`);
  if (res.ok) {
    return await res.json();
  }
};

export interface VenueContact {
  ID: string;
  Role: number;
  Scope: number;
  ReplaceCurrentRoles: boolean;
}

export const addVenueContactSystemRole = async (venueContact: VenueContact) => {
  const res = await fetchWithAuth(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v0/Tournaments/AddVenueContactSystemRole`, {
    method: 'POST',
    body: JSON.stringify(venueContact),
    headers: { 'content-type': 'application/json' },
  });
  if (res.ok) {
    return res.json();
  }
};

export const getVenueContact = async (contactId: string) => {
  const res = await fetchWithAuth(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/v1/VenueAdminContacts/GetVenueContact/${contactId}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    },
  );
  if (res.ok) return res.json();
};
