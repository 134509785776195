import { Option } from '../components/dropdown/dropdown';

export interface AgeCategoryOption extends Option {
  subType?: string;
}

export type ModuleID =
  | 'college'
  | 'memberships'
  | 'lta-memberships'
  | 'reports'
  | 'staff'
  | 'tournaments'
  | 'rankings'
  | 'rankings-usta'
  | 'players'
  | 'suspension'
  | 'schools'
  | 'organisations'
  | 'organisations/manage-organisations'
  | 'ita-players'
  | 'ita-merge-players'
  | 'areas';

type Language = 'sl-SI' | 'en-US';

export interface ClientConfig {
  disableModules?: ModuleID[];
  clientName: string;
  logoFilename?: string;
  logoAltText: string;
  logoSmallFilename: string;
  faviconFilename: string;
  footerLogoFilename?: string;
  rootUrlRedirectsToTry?: string[];
  siteTitle: string;
  siteDescription: string;
  footerCopyright: string;
  ignoreScopes?: boolean;
  hideFacilitySelect?: boolean;
  headTaxConfigurable?: boolean;
  inviteStaffViaEmailAndName?: boolean;
  language?: Language;
  fallbackLanguages?: Language[];
  disableTournamentSettings?: boolean;
  disableOrgHierarchyRestrictions?: boolean;
  safeplay?: boolean;
  stripeCountry?: string;
  isSaaS?: boolean;
  datePickerDateFormat?: string;
  enableManualRankings?: boolean;
  tournamentOrgMembership?: boolean;
  noRootProvider?: boolean;
  membershipYearOptions?: Option[];
  ageCategories?: AgeCategoryOption[];
}

export const getClientConfig = (): ClientConfig => {
  switch (process.env.GATSBY_CLIENT) {
    case 'ITA':
      return require('./ita').default;
    case 'LTA':
      return require('./lta').default;
    case 'TSI':
      return require('./tsi').default;
    case 'DMOR':
      return require('./dmor').default;
    case 'STA':
      return require('./sta').default;
    case 'SAAS':
      return require('./saas').default;
    case 'GENERIC':
      return require('./generic').default;
    case 'TC':
      return require('./tc').default;
    default:
      return require('./usta').default;
  }
};

export const getEnvConfig = (): { [key: string]: string } => {
  if (typeof window !== 'undefined') {
    return window['__env__'];
  }

  return {};
};
