import React, { ButtonHTMLAttributes } from 'react';

import cx from 'classnames';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

import { LevelProps, useLevelColour } from '../../hooks/level-colour';
import Icon, { IconProps } from '../icon/icon';
import Spinner from '../spinner/spinner';
import * as styles from './button.module.less';

export interface ButtonProps extends LevelProps, SpacingProps, ButtonHTMLAttributes<HTMLButtonElement> {
  fluid?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  loading?: boolean;
  linkStyle?: boolean;
  invertedLinkStyle?: boolean;
  icon?: IconProps['name'];
  iconClassname?: string;
  testId?: string;
  noWidth?: boolean;
  hide?: boolean;
  mockDisabledStyle?: boolean;
}

const sizeClassMap = { xs: 'x-small', sm: 'small', md: 'medium', lg: 'large' };

const Button: React.FC<ButtonProps> = ({
  children,
  level = 'primary',
  fluid,
  size = 'md',
  loading,
  linkStyle,
  invertedLinkStyle,
  spacing,
  icon,
  iconClassname,
  noWidth,
  className,
  hide,
  mockDisabledStyle,
  testId = 'button',
  ...props
}) => {
  const spacingClass = useSpacing(spacing);
  const lvlClass = useLevelColour(level, true);

  if (hide) return null;

  return (
    <button
      {...props}
      data-testid={testId}
      disabled={loading || props.disabled}
      className={cx(className, styles.button, styles[sizeClassMap[size]], spacingClass, {
        [lvlClass]: !linkStyle && !invertedLinkStyle,
        [styles.linkButton]: linkStyle,
        [styles.invertedLink]: invertedLinkStyle,
        [styles.fluid]: fluid,
        [styles.spinnerPadding]: loading,
        [styles.noWidth]: noWidth,
        [styles.mockDisabled]: mockDisabledStyle,
      })}
    >
      {loading ? (
        <Spinner fluid />
      ) : (
        <>
          {icon && (
            <div className={styles.iconContainer}>
              <Icon
                data-testid="icon"
                className={iconClassname ?? (level === 'primary' ? styles.iconLight : styles.icon)}
                name={icon}
              />
            </div>
          )}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;
