const ROUTES = {
  LOGIN: '/login',
  LTA_MEMBERSHIPS: '/lta-memberships',
  MEMBERSHIPS: '/memberships',
  RANKINGS: '/rankings',
  TOURNAMENTS: '/tournaments',
  TOURNAMENT_GROUPS: '/tournaments/groups',
  TOURNAMENT_DIRECTORS: '/tournaments/directors',
  LEVEL_CONFIG: '/tournaments/level-config',
  PAYOUTS: '/tournaments/payouts-report',
  FINANCIAL_REPORTS: '/tournaments/financial-reports',
  TOURNAMENTS_REPORT: '/tournaments/tournaments-report',
  TOURNAMENT_SETTINGS: '/tournaments/settings',
  STAFF: '/staff',
  REPORTS: '/reports',
  ADD_STAFF: '/staff/add',
  PLAYERS: '/players',
  ITA_PLAYERS: '/players',
  MERGE_PLAYERS: '/merge-players',
  SUSPENSION: '/suspension',
  SUSPENSION_POINTS: '/suspension/points',
  SUSPENSION_CODES: '/suspension/codes',
  SCHOOLS: '/schools',
  PLAYER_POINTS: '/player-points',
  RANKING_REPORTS: '/reports/ranking-reports',
  ORGANISATIONS: '/organisations',
  MANAGE_SCHOOLS: '/organisations/manage-schools',
  MANAGE_ORGANIZATIONS: '/organisations/manage-organisations',
  AREAS: '/areas',
  AREAS_COORDINATORS: '/areas/manage-coordinators',
  LEAGUES: '/leagues',
  LEAGUES_COORDINATORS: '/leagues/leagues-coordinators',
  LEAGUES_LEVEL_CONFIG: '/leagues/level-config',
};

export default ROUTES;
